<template>
  <div class="privacy-policy">
    <q-page-container>
      <q-page>
        <div class="information-wrapper">
          <div
            v-for="(information, index) in informations"
            :key="index"
            class="information-item"
          >
            <div>{{ information.title }}</div>
            <p>{{ information.description }}</p>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
const informations = [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut orci amet pellentesque. Facilisis in non adipiscing sit mi in nisl, interdum. Aliquet tristique in nibh amet, sed ultrices neque ultricies. Ultricies nulla nibh sodales imperdiet auctor volutpat. Parturient vel, nunc pulvinar sed augue a volutpat suspendisse feugiat. In egestas quisque diam risus. Maecenas lacus id praesent cursus egestas elit elit, pretium. Donec luctus erat at ullamcorper.'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut orci amet pellentesque. Facilisis in non adipiscing sit mi in nisl, interdum. Aliquet tristique in nibh amet, sed ultrices neque ultricies. Ultricies nulla nibh sodales imperdiet auctor volutpat. Parturient vel, nunc pulvinar sed augue a volutpat suspendisse feugiat. In egestas quisque diam risus. Maecenas lacus id praesent cursus egestas elit elit, pretium. Donec luctus erat at ullamcorper.'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut orci amet pellentesque. Facilisis in non adipiscing sit mi in nisl, interdum. Aliquet tristique in nibh amet, sed ultrices neque ultricies. Ultricies nulla nibh sodales imperdiet auctor volutpat. Parturient vel, nunc pulvinar sed augue a volutpat suspendisse feugiat. In egestas quisque diam risus. Maecenas lacus id praesent cursus egestas elit elit, pretium. Donec luctus erat at ullamcorper.'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut orci amet pellentesque. Facilisis in non adipiscing sit mi in nisl, interdum. Aliquet tristique in nibh amet, sed ultrices neque ultricies. Ultricies nulla nibh sodales imperdiet auctor volutpat. Parturient vel, nunc pulvinar sed augue a volutpat suspendisse feugiat. In egestas quisque diam risus. Maecenas lacus id praesent cursus egestas elit elit, pretium. Donec luctus erat at ullamcorper.'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut orci amet pellentesque. Facilisis in non adipiscing sit mi in nisl, interdum. Aliquet tristique in nibh amet, sed ultrices neque ultricies. Ultricies nulla nibh sodales imperdiet auctor volutpat. Parturient vel, nunc pulvinar sed augue a volutpat suspendisse feugiat. In egestas quisque diam risus. Maecenas lacus id praesent cursus egestas elit elit, pretium. Donec luctus erat at ullamcorper.'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut orci amet pellentesque. Facilisis in non adipiscing sit mi in nisl, interdum. Aliquet tristique in nibh amet, sed ultrices neque ultricies. Ultricies nulla nibh sodales imperdiet auctor volutpat. Parturient vel, nunc pulvinar sed augue a volutpat suspendisse feugiat. In egestas quisque diam risus. Maecenas lacus id praesent cursus egestas elit elit, pretium. Donec luctus erat at ullamcorper.'
  }
]

export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      informations
    }
  }
}
</script>
